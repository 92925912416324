
.brand {
  width: 123px;
  height: 26px;
}

.header > a{
  font-family: 'Futura';
  color:white;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.large > .header {
  height: 64px;
  align-items: center;
}

.small > .header {
  padding-bottom: 20px;
  height: 112px;
  align-items: flex-end;
}

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 64px;
  background-color: black;
  z-index: 1000;
}

.content {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  height: 100%;
}

.background {
  background-color: black;
  object-fit: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1000;
}

.tips {
  display: flex;
  flex-direction: column;
  color: #EEEEEE;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: 'RobotoMono';
}